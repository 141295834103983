body,
html,
#root {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f7f6;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
}

header {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  width: 120px;
  margin-bottom: 20px;
}

select {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333333;
  background: #ffffff;
  cursor: pointer;
}

select:focus {
  border-color: #007BFF;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}