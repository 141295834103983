.form {
  max-width: 600px;
  width: 100%;
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333333;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="tel"],
.form input[type="text"],
.form input[type="email"],
.form input[type="tel"],
.form .contact-method {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333333;
  box-sizing: border-box;
}

.form input[type="text"]:focus,
.form input[type="email"]:focus,
.form input[type="tel"]:focus,
.form .contact-method:focus {
  border-color: #007BFF;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.form .contact-method {
  display: block;
}

.form .contact-method label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.form .contact-method-options {
  display: block;
  /* Ensure the options container is block */
}

.form .contact-method-options label {
  display: flex;
  /* Use flex to align radio button and text on the same line */
  align-items: center;
  /* Center align the radio button with the text */
  margin-bottom: 10px;
  /* Space between each option */
  font-weight: normal;
}

.form .contact-method-options input[type="radio"] {
  margin-right: 10px;
  /* Space between radio button and text */
}

.form button {
  width: 100%;
  padding: 15px;
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.form button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.form button:hover:not(:disabled) {
  background: #0056b3;
}

.error {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

.required {
  color: red;
  margin-left: 5px;
}

.span-width {
  width: 65px;
}