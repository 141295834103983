.not-found {
    text-align: center;
    margin-top: 50px;
}

.not-found h1 {
    font-size: 6em;
    margin-bottom: 20px;
}

.not-found p {
    font-size: 1.5em;
    margin-bottom: 30px;
}

.not-found button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.not-found button:hover {
    background-color: #0056b3;
}