.confirmation {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirmation h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
}

.confirmation p {
    font-size: 1.2em;
    color: #666;
}