.header {
  text-align: center;
  margin-bottom: 30px;
}

.logo {
  width: 120px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
  color: #666;
}